let ads = [
  {
    title: "Como es hoy",
    color: "#F0AB1B",
    text: `
      <h4>Más de lo mismo</h4>
      <p>“El estándar de belleza idealizado en Brasil sigue siendo una mujer blanca delgada, con curvas, lacio y cabello castaño. Este patrón aparece en el 63% de los protagonistas de TV y Facebook”.</p>
      <p class="cite"><b>(Todxs, 9º Onda)</b></p>
      <h4>¿Y todavía crees que está bien?</h4>
      <p>“El estándar de belleza masculino ideal en Brasil es blanco, musculoso (abdomen y brazos), cabello castaño liso.”.</p>
      <p class="cite"><b>(Todxs, 9º Onda)</b></p>
    `
  },
  {
    title: "Como debería ser",
    color: "#94C15B",
    text: `
      <h4>Menos curvas, más cuerpos reales</h4>
      <p>“Según una encuesta de la Asociación Brasileña de Normas Técnicas (ABNT), el 76% de las mujeres brasileñas tienen una forma de cuerpo rectangular, con mínima diferencia entre el pecho y las caderas, y una cintura ligeramente pronunciada.”</p>
      <p><b>DISPONIBLE: <a href="https://veja.abril.com.br/comportamento/maioria-das-brasileiras-nao-tem-o-chamado-corpo-violao-diz-pesquisa/" target="_blank" rel="noreferrer">https://veja.abril.com.br/comportamento/maioria-das-brasileiras-nao-tem-o-chamado-corpo-violao-diz-pesquisa/</a></b></p>
      <div class="columns">
        <h4>Menos blanco,<br/> más negro, moreno,<br/> amarillo e indígena</h4>
        <ul>
          <li>
            <h5>42,7%</h5>
            <p>Minoría <br/>Blanca</p>
          </li>
          <li>
            <h5>57,3%</h5>
            <p>Los más <br/>diversos</p>
          </li>
        </ul>
      </div>
      <p class="cite right"><b>Población brasileña (IBGE - PNAD, 2012-2019)</b></p>
    `
  },
]
export function getAdsES() {
  return ads
}